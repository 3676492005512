
/**
 * アクションの種別(ポストバックやQR等々全部入り)
 */
export const ACTION_TYPES = {
    NONE: 'NONE',
    ADD_TAG: 'ADD_TAG',
    REMOVE_TAG: 'REMOVE_TAG',
    SWITCH_RICH_MENU: 'SWITCH_RICH_MENU',
    SEND_TEMPLATE_MESSAGE: 'SEND_TEMPLATE_MESSAGE',
    VISIT: 'VISIT',
    STEP: 'STEP',
} as const

export type ActionType = typeof ACTION_TYPES[keyof typeof ACTION_TYPES]

/**
 * 文字列からアクション種別を取得
 */
export function actionTypeOf(s: string): ActionType {
    const x = Object.values(ACTION_TYPES).filter(t => t == s)
    if (x) {
        return x[0]
    } else {
        return ACTION_TYPES.NONE
    }
}

/**
 * アクション定義
 */
export class ActionDefinition {
    /** アクションID */
    id?: string

    /** アクションの種別 */
    type: ActionType

    /** タグ追加・削除時のタグ情報 */
    tags?: TagItem[]

    /** 送信するメッセージテンプレートID */
    messageId?: string

    /** 切り替え先リッチメニューID */
    menuId?: string

    /** 来店記録時のスポットID */
    spotId?: string

    /** 実行するステップ配信のID */
    stepId?: string

    /** 表示順 */
    order?: number
}

/**
 * タグ情報
 */
export interface TagItem {
    /** タグID */
    id: string

    /** タグ名 */
    name?: string

    /** 表示スタイル */
    style?: string
}