
/**
 * カテゴリー移動
 */
export class CategoryMover {
    private _moveForm: HTMLFormElement
    private _activeElement: HTMLElement | null

    constructor(container: HTMLElement) {
        // 項目移動用のフォームをセットアップ
        const form = this.findMoveForm(container)
        if (!form) {
            // フォームが無い場合は異動処理できないので何もしない
            return
        }
        this._moveForm = form

        // ドロップ先(カテゴリー)のセットアップ
        this.setupDragTarget(container)
        // ドラッグ元(移動する項目)のセットアップ
        this.setupDragSource(container)

        // アクティブなカテゴリーの項目を取得しておく
        this._activeElement = this.findActiveElement(container)
    }

    private onTargetDragOver(evt: DragEvent): void {
        evt.preventDefault()

        const dt = evt.dataTransfer
        if (dt) {
            dt.dropEffect = 'move'
        }
    }

    private onTargetDragEnter(evt: DragEvent): void {
        evt.preventDefault()

        const x = evt.currentTarget as HTMLElement
        if (!x) {
            return
        }
        x.classList.add('dragging')
    }

    private onTargetDragLeave(evt: DragEvent): void {
        evt.preventDefault()

        const x = evt.currentTarget as HTMLElement
        if (!x) {
            return
        }
        x.classList.remove('dragging')
    }

    private onTargetDrop(evt: DragEvent): void {
        evt.preventDefault()

        const x = evt.currentTarget as HTMLElement
        if (!x) {
            return
        }
        x.classList.remove('dragging')

        const dt = evt.dataTransfer
        if (!dt) {
            return
        }

        const dataId = dt.getData('itemid')
        if (!dataId || dataId.length == 0) {
            return
        }

        // ドロップ先のカテゴリーIDを取得
        const categoryId = x.getAttribute('data-category-id') ?? ''

        // フォームをいったん綺麗に
        this.clearFormInputs()

        // アクションIDを入れておくINPUTを作成
        const actionIdInput = document.createElement('input')
        actionIdInput.classList.add('move-dropped')
        actionIdInput.type = 'hidden'
        actionIdInput.name = 'itemId'
        actionIdInput.value = dataId
        // 移動先カテゴリーIDを入れておくINPUTを作成
        const categoryIdInput = document.createElement('input')
        categoryIdInput.classList.add('move-dropped')
        categoryIdInput.type = 'hidden'
        categoryIdInput.name = 'categoryId'
        categoryIdInput.value = categoryId

        // フォームにINPUTを追加
        this._moveForm.appendChild(actionIdInput)
        this._moveForm.appendChild(categoryIdInput)

        // フォームSubmit
        this._moveForm.submit()
    }

    private onItemDragStart(evt: DragEvent): void {
        // ドラッグが開始された
        // 現在表示中のカテゴリにはドロップできない
        this._activeElement?.classList?.add('disabled')

        const dt = evt.dataTransfer
        if (!dt) {
            return
        }
        const elem = evt.currentTarget as Element
        if (!elem) {
            return
        }
        const dataId = elem.getAttribute('data-id')
        if (!dataId) {
            return
        }

        dt.setData('itemid', dataId)
        dt.effectAllowed = 'move'
    }

    private onItemDragEnd(): void {
        this._activeElement?.classList?.remove('disabled')
    }

    private setupDragTarget(container: HTMLElement) {
        const movableTargets = container.getElementsByClassName('move-category-target')
        for (const m of movableTargets) {
            if (!(m instanceof HTMLElement)) {
                continue
            }
            m.addEventListener('dragover', evt => this.onTargetDragOver(evt))
            m.addEventListener('dragenter', evt => this.onTargetDragEnter(evt))
            m.addEventListener('dragleave', evt => this.onTargetDragLeave(evt))
            m.addEventListener('drop', evt => this.onTargetDrop(evt))
        }
    }

    private setupDragSource(container: HTMLElement) {
        const movableItems = container.getElementsByClassName('move-category-item')
        for (const m of movableItems) {
            if (!(m instanceof HTMLElement)) {
                continue
            }
            m.draggable = true
            m.addEventListener('dragstart', evt => this.onItemDragStart(evt))
            m.addEventListener('dragend', () => this.onItemDragEnd())
        }
    }

    private clearFormInputs() {
        this._moveForm.querySelectorAll('input.move-dropped').forEach(e => {
            this._moveForm.removeChild(e)
        })
    }

    private findMoveForm(container: HTMLElement): HTMLFormElement | null {
        const e = container.getElementsByTagName('form')
        if (e.length == 0) {
            return null
        }
        return e[0]
    }

    private findActiveElement(container: HTMLElement): HTMLElement | null {
        const e = container.querySelector('.category-list-container li .active')
        if (!e) {
            return null
        }
        if (!(e instanceof HTMLElement)) {
            return null
        }

        return e
    }
}