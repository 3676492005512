import { cn1 } from "../utils";
import { ACTION_TYPES, ActionDefinition } from "./actionObjects";
import { BaseAction } from "./BaseAction";

/**
 * リッチメニュー切り替えアクション
 */
export class SwitchRichMenuAction extends BaseAction {
    private _selector: HTMLSelectElement

    constructor(parent: HTMLElement) {
        super(parent, 'action-switch-rich-menu', ACTION_TYPES.SWITCH_RICH_MENU)

        const s = cn1(this._container, 'rich-menu-select') as HTMLSelectElement
        if (!s) {
            throw 'Cannot find RichMenu selector.'
        }
        this._selector = s
    }

    /**
     * アクション定義を画面に反映
     * 
     * @param action アクション定義
     */
    apply(action: ActionDefinition): void {
        this._selector.value = action.menuId ?? ''
    }

    /**
     * アクション定義オブジェクトを作成
     */
    buildActionJson(): ActionDefinition | null {
        let menuId: string | undefined = this._selector.value
        if (!menuId) {
            // 切り替え先メニューIDが未設定の場合は「デフォルトに戻す」なのでエラーにはしない
            menuId = undefined
        }

        return {
            type: this._type,
            menuId: menuId,
        }
    }
}