import { DateInput } from "./date"

/**
 * 日時選択フォーム
 */
export class DateTimeInput {
    private _date: DateInput
    private _hour: HTMLSelectElement
    private _minute: HTMLSelectElement

    constructor(parent: HTMLElement) {
        this.createInput(parent)
    }

    apply(dt: string): void {
        if (!dt) {
            return
        }
        const date = dt.substring(0, 10)
        const hour = dt.substring(11, 13)
        const minute = dt.substring(14, 16)

        this._date.setDate(date)
        this._hour.value = hour
        this._minute.value = minute

        this._hour.classList.remove('is-invalid')
        this._minute.classList.remove('is-invalid')
    }

    buildDateTime(): string {
        const date = this._date.value()
        const hour = this._hour.value
        const minute = this._minute.value
        return `${date} ${hour}:${minute}`
    }

    validate(): boolean {
        this._hour.classList.remove('is-invalid')
        this._minute.classList.remove('is-invalid')

        if (!this._date.validate()) {
            return false
        }
        const hour = this._hour.value
        if (hour.length != 2) {
            this._hour.classList.add('is-invalid')
            return false
        }
        const minute = this._minute.value
        if (minute.length != 2) {
            this._minute.classList.add('is-invalid')
            return false
        }

        return true
    }

    private createInput(parent: HTMLElement): void {
        // 日付部分
        const dateInput = parent.querySelector('input[data-picker-type="date"]')
        if (dateInput instanceof HTMLInputElement) {
            this._date = new DateInput(dateInput)
        }
        // 時
        const hourInput = parent.querySelector('select[data-picker-type="hour"]')
        if (hourInput instanceof HTMLSelectElement) {
            this._hour = hourInput
        }
        // 分
        const minuteInput = parent.querySelector('select[data-picker-type="minute"]')
        if (minuteInput instanceof HTMLSelectElement) {
            this._minute = minuteInput
        }
    }
}