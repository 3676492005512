import { ACTION_TYPES, ActionType, actionTypeOf, AddTagAction, BaseAction, RemoveTagAction, SendTemplateAction, SwitchRichMenuAction } from "../actions";
import { ActionDefinition } from "../actions/actionObjects";
import { VisitSpot } from "../actions/VisitSpot";
import { BaseComponent } from "../common/BaseComponent";
import { cloneNode, cn1, show } from "../utils";

/** アクション定義用テンプレートDOMのID */
const ACTION_ITEM_TEMPLATE_ID = 'action-item-template'

/** アクションタイプ選択のクラス名 */
const INPUT_ACTION_TYPE_CLASSNAME = 'action-type'
/** アクション削除ボタンのクラス名 */
const BUTTON_REMOVE_ACTION_CLASSNAME = 'btn-remove-action'

/**
 * アクション定義設定パネル
 */
export class ActionPanel extends BaseComponent {
    /** 利用可能なアクションのリスト */
    private _actions: { [key: string]: BaseAction } = {}
    /** 選択しているアクション種別 */
    private _selectedType: ActionType = ACTION_TYPES.NONE

    private _onRemovedHandler: () => void = this.defaultOnRemovedHandler

    constructor() {
        super()

        // アクション定義用DOMを作成
        const panel = this.createItemElement()
        // 保持
        this.setElement(panel)

        // 利用可能アクション設定
        this.setupAvailableActions()
    }

    /**
     * アクション定義を画面に反映
     */
    applyAction(action: ActionDefinition): void {
        const p = this.getElement()
        if (action.id) {
            p.setAttribute('data-action-id', action.id)
        }

        // アクションタイプ
        const typeSelect = cn1(p, INPUT_ACTION_TYPE_CLASSNAME) as HTMLSelectElement
        if (typeSelect) {
            typeSelect.value = action.type
        }
        this.onActionTypeChanged(action.type)

        // 各アクションの設定値を画面に反映
        this._actions[action.type].apply(action)
    }

    /**
     * アクション定義オブジェクトを作成
     */
    buildActionJson(): ActionDefinition | null {
        if (this._selectedType == ACTION_TYPES.NONE) {
            // 未定義なのでダメ
            return null
        }

        const obj = this._actions[this._selectedType].buildActionJson()
        if (!obj) {
            return null
        }

        // IDを設定しておく
        obj.id = this.getElement().getAttribute('data-action-id') ?? undefined

        return obj
    }

    /**
     * このアクションが削除されるときにコールされるハンドラを登録
     */
    setOnRemovedHandler(handler: () => void): void {
        this._onRemovedHandler = handler
    }

    /**
     * アクションパネル新規作成
     */
    static createNewPanel(): ActionPanel {
        // まずはインスタンス生成
        const action = new ActionPanel()
        // 利用可能なアクションのうち最初の項目をセットしておく
        action.setFirstAvailable()
        // 生成したパネルを返却
        return action
    }

    /**
     * アクションタイプが変更されたときの処理
     */
    protected onActionTypeChanged(type: ActionType): void {
        // 選択したアクションタイプによって表示を切り替える
        Object.keys(ACTION_TYPES).forEach(k => this._actions[k]?.showIfTypeMatched(type))
        // 選択したアクションタイプを保持
        this._selectedType = type
    }

    private setupAvailableActions(): void {
        const p = this.getElement()
        this._actions[ACTION_TYPES.ADD_TAG] = new AddTagAction(p)
        this._actions[ACTION_TYPES.REMOVE_TAG] = new RemoveTagAction(p)
        this._actions[ACTION_TYPES.SWITCH_RICH_MENU] = new SwitchRichMenuAction(p)
        this._actions[ACTION_TYPES.SEND_TEMPLATE_MESSAGE] = new SendTemplateAction(p)
        this._actions[ACTION_TYPES.VISIT] = new VisitSpot(p)
    }

    private setFirstAvailable(): void {
        // アクションタイプの選択リストを取得
        const select = cn1(this.getElement(), INPUT_ACTION_TYPE_CLASSNAME) as HTMLSelectElement
        if (!select) {
            return
        }

        if (select.options.length == 0) {
            // 選択できるアクションが無い…？？
            return
        }

        // 最初の選択肢を取得
        const firstOption = select.options[0]
        // 画面に反映
        this.onActionTypeChanged(actionTypeOf(firstOption.value))
    }

    /**
     * 削除ボタンが押された
     */
    private onActionRemove(): void {
        if (confirm('アクションを削除しますか？')) {
            // アクション削除確認OKなので、登録されたイベントハンドラをコール
            this._onRemovedHandler()
        }
    }

    /**
     * アクション定義用DOMを生成
     */
    private createItemElement(): HTMLElement {
        const p = cloneNode(ACTION_ITEM_TEMPLATE_ID)
        if (!p) {
            throw 'Cannot create action item element.'
        }

        // アクションタイプが変更されたときの処理
        cn1(p, INPUT_ACTION_TYPE_CLASSNAME)?.addEventListener('change', evt => {
            const type = actionTypeOf((evt.target as HTMLSelectElement).value)
            this.onActionTypeChanged(type)
        })

        // 削除ボタンが押されたときの処理
        const removeButton = cn1(p, BUTTON_REMOVE_ACTION_CLASSNAME)
        if (removeButton) {
            removeButton.addEventListener('click', () => this.onActionRemove())
        }

        // 表示しておく
        show(p)

        return p
    }

    /**
     * ブロック削除時のイベントハンドラが未登録時に実行される
     */
    private defaultOnRemovedHandler(): void {
        console.dir('action removed.')
    }
}