import { Modal } from 'bootstrap'
import { cn1 } from '../utils'

const BUTTON_MODAL_CONFIRM_CLASS_NAME = 'button-modal-confirm'

export abstract class BaseModal {
    private _element: HTMLElement

    private _modal: Modal

    private _confirmed: boolean = false

    private _onConfirmBind
    private _onModalClosedBind

    constructor(modalElement: HTMLElement) {
        this._element = modalElement
        this.setupDialog()

        // bootstrapモーダル生成
        this._modal = new Modal(modalElement)
    }

    show(): void {
        this._modal.show()
    }

    getElement(): HTMLElement {
        return this._element
    }

    private setupDialog(): void {
        // 設定ボタン選択時の処理
        this._onConfirmBind  = this.onModalConfirm.bind(this)
        cn1(this._element, BUTTON_MODAL_CONFIRM_CLASS_NAME)?.addEventListener('click', this._onConfirmBind)

        // モーダルが閉じられた時の処理
        this._onModalClosedBind = this.onModalClosed.bind(this)
        this._element.addEventListener('hidden.bs.modal', this._onModalClosedBind)
    }

    protected onModalConfirm(): void {
        if (this.validate()) {
            // 設定OKフラグ設定
            this._confirmed = true
            // モーダルを閉じる
            this._modal.hide()
        }
    }

    protected onModalClosed(): void {
        if (this._confirmed) {
            this.onConfirmed()
        }
        // ダイアログ破棄
        this.dispose()
    }

    protected onConfirmed(): void {
    }

    protected validate(): boolean {
        return true
    }

    protected onDispose() {
    }

    private dispose() {
        this.onDispose()
        cn1(this._element, BUTTON_MODAL_CONFIRM_CLASS_NAME)?.removeEventListener('click', this._onConfirmBind)
        this._element.removeEventListener('hidden.bs.modal', this._onModalClosedBind)
        this._modal.dispose()
    }
}