import { ACTION_TYPES } from "./actionObjects"
import { BaseTagAction } from "./BaseTagAction"

/**
 * タグ追加
 */
export class AddTagAction extends BaseTagAction {
    constructor(parent: HTMLElement) {
        super(parent, 'action-add-tag', ACTION_TYPES.ADD_TAG)
    }
}