import { SpotSelector } from "../spotSelector/SpotSelector";
import { cn1 } from "../utils";
import { ACTION_TYPES, ActionDefinition } from "./actionObjects";
import { BaseAction } from "./BaseAction";

/**
 * 選択したスポット名を表示するDOMクラス名
 */
const PANEL_SPOT_CLASSNAME = 'visit-spot'

/**
 * 来店記録アクション
 */
export class VisitSpot extends BaseAction {
    private _visitSpotPanel: HTMLElement

    private _selectedSpotId: string | undefined

    constructor(parent: HTMLElement) {
        super(parent, 'action-visit-spot', ACTION_TYPES.VISIT)

        // スポット名記載エリア
        const p = cn1(this._container, PANEL_SPOT_CLASSNAME)
        if (!p) {
            return
        }

        this._visitSpotPanel = p
        this.applySpot(undefined)

        // スポット選択ボタンが押されたときの処理
        const btn = cn1(this._container, 'action-select-spot')
        btn?.addEventListener('click', () => this.showSpotSelector())
    }

    /**
     * アクション定義を画面に反映
     * 
     * @param action アクション定義
     */
    apply(action: ActionDefinition): void {
        this.applySpot(action.spotId)
    }

    /**
     * アクション定義オブジェクトを作成
     */
    buildActionJson(): ActionDefinition | null {
        if (!this._selectedSpotId) {
            // スポットが選択されていない
            this._visitSpotPanel.parentElement?.classList.add('is-invalid')
            return null
        }

        return {
            type: this._type,
            spotId: this._selectedSpotId,
        }
    }

    private applySpot(id: string | undefined): void {
        // 一旦スポット名の中身をクリア
        this._visitSpotPanel.innerText = ''

        const d = document.createElement('div')
        if (id) {
            // スポット選択ダイアログに名称があるのでそれを使う
            d.innerText = document.getElementById(`spot-${id}`)?.innerText ?? ''
        } else {
            // 未選択を表示
            d.classList.add('text-danger')
            d.innerText = 'スポットが選択されていません。'
        }
        this._visitSpotPanel.appendChild(d)

        // スポット空欄でアクション定義生成したらエラー枠線が表示されるので、編集時にクリアしておく
        this._visitSpotPanel.parentElement?.classList.remove('is-invalid')
        // 選択したスポットを保持
        this._selectedSpotId = id
    }

    /**
     * スポット選択ダイアログを表示
     */
    private showSpotSelector(): void {
        const s = new SpotSelector({
            spotId: this._selectedSpotId,
            confirmed: spotId => this.applySpot(spotId)
        })
        s.show()
    }
}