import { BaseActionEditor, ActionPanel } from "../actionEdtior";
import instances from "../common/instances";

/** アクションエディタテンプレートのID */
const ACTION_EDITOR_TEMPLATE_ID = 'qr-action-editor-template'

/**
 * QRアクション、各アクションエディタ
 */
export class QRActionEditor extends BaseActionEditor {
    constructor(container: HTMLElement) {
        super(container, ACTION_EDITOR_TEMPLATE_ID)
    }

    /**
     * QRアクションエディタインスタンスを取得する
     * 
     * @param containerElement コンテナDOM要素
     */
    static getOrCreateInstance(containerElement: HTMLElement): QRActionEditor {
        let obj = instances.get(containerElement)
        if (!obj) {
            obj = new QRActionEditor(containerElement)
            instances.set(containerElement, obj)
        }
        return obj as QRActionEditor
    }
}