import { DateTimeInput } from '../forms/dateTime'
import { BaseModal } from '../common/BaseModal'

const RESERVE_DATE_SELECTOR_MODAL_ID = 'reserve-date-picker-modal'

export interface ReserveDateSelectOptions {
    dateTime: string
    confirmed?: (dt: string) => void
}

function createReserveDateModalElement(): HTMLElement {
    const p = document.getElementById(RESERVE_DATE_SELECTOR_MODAL_ID)
    if (!p) {
        throw 'Cannot find reserve date selector modal'
    }
    return p
}

/**
 * 配信予定日時選択ダイアログ
 */
export class ReserveDateSelector extends BaseModal {
    private _options: ReserveDateSelectOptions
    private _dateTime: DateTimeInput

    constructor(options: ReserveDateSelectOptions) {
        super(createReserveDateModalElement())
        
        this._options = options
        this._dateTime = new DateTimeInput(this.getElement())

        // 現在の選択をダイアログに反映
        this._dateTime.apply(options.dateTime)
    }

    protected validate(): boolean {
        if (!this._dateTime.validate()) {
            return false
        }
        return true
    }

    protected onConfirmed(): void {
        if (this._options.confirmed) {
            const dt = this._dateTime.buildDateTime()
            this._options.confirmed(dt)
        }
    }
}