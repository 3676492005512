import { cn1 } from "../utils";
import { ACTION_TYPES, ActionDefinition } from "./actionObjects";
import { BaseAction } from "./BaseAction";

/**
 * メッセージテンプレート送信アクション
 */
export class SendTemplateAction extends BaseAction {
    private _selector: HTMLSelectElement

    constructor(parent: HTMLElement) {
        super(parent, 'action-send-message', ACTION_TYPES.SEND_TEMPLATE_MESSAGE)

        const s = cn1(this._container, 'message-select') as HTMLSelectElement
        if (!s) {
            throw 'Cannot find MessageTemplate selector.'
        }
        this._selector = s
    }

    /**
     * アクション定義を画面に反映
     * 
     * @param action アクション定義
     */
    apply(action: ActionDefinition): void {
        this._selector.value = action.messageId ?? ''
    }

    /**
     * アクション定義オブジェクトを作成
     */
    buildActionJson(): ActionDefinition | null {
        const messageId = this._selector.value
        if (!messageId) {
            this._selector.classList.add('is-invalid')
            return null
        }

        this._selector.classList.remove('is-invalid')

        return {
            type: this._type,
            messageId: messageId,
        }
    }
}