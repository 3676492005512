import { cn1, hide, show } from "../utils"
import { ActionDefinition, ActionType } from "./actionObjects"

/**
 * 各アクション - アクション設定ベース
 */
export abstract class BaseAction {
    protected _container: HTMLElement
    protected _type: ActionType

    /**
     * コンストラクタ
     * 
     * @param parent アクション定義DOMを格納する親要素のDOM
     * @param panelClassName 設定パネルのクラス名
     * @param type アクションタイプ文字列
     */
    constructor(parent: HTMLElement, panelClassName: string, type: ActionType) {
        // アクション設定パネル
        const c = cn1(parent, panelClassName)
        if (!c) {
            return
        }

        this._type = type
        this._container = c
    }

    abstract apply(action: ActionDefinition): void

    abstract buildActionJson(): ActionDefinition | null

    /**
     * クラスのアクション種別を引数のアクション種別を比較して、同じなら表示、違ったら非表示に
     * 
     * @param checkType 選択した種別
     */
    showIfTypeMatched(checkType: string): void { 
        if (this._type == checkType) {
            show(this._container)
        } else {
            hide(this._container)
        }
    }
}