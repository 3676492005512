import { cn1 } from '../utils'
import { DateTimeInput } from '../forms/dateTime'
import { BaseModal } from '../common/BaseModal'

const LIMIT_DATE_SELECTOR_MODAL_ID = 'limit-date-picker-modal'

export interface LimitDateSelectOptions {
    dateTime: string
    confirmed?: (dt: string) => void
}

function createLimitDateModalElement(): HTMLElement {
    const p = document.getElementById(LIMIT_DATE_SELECTOR_MODAL_ID)
    if (!p) {
        throw 'Cannot find limit date selector modal'
    }
    return p
}

/**
 * 回答期限日時選択ダイアログ
 */
export class LimitDateSelector extends BaseModal {
    private _options: LimitDateSelectOptions
    private _dateTime: DateTimeInput

    private _deleted: boolean = false
    private _onDeleteBind

    constructor(options: LimitDateSelectOptions) {
        super(createLimitDateModalElement())

        this._options = options
        this._dateTime = new DateTimeInput(this.getElement())

        this.setupLimitDateDialog()

        // 現在の選択をダイアログに反映
        this._dateTime.apply(options.dateTime)
    }

    protected onConfirmed(): void {
        if (this._options.confirmed) {
            const dt = (this._deleted) ? '' : this._dateTime.buildDateTime()
            this._options.confirmed(dt)
        }
    }

    protected validate(): boolean {
        if (this._deleted) {
            // 回答期限なしなので、特に値チェックしない
            return true
        }
        if (!this._dateTime.validate()) {
            return false
        }
        return true
    }

    private onDeleteDate(): void {
        this._deleted = true
        this.onModalConfirm()
    }

    protected onDispose(): void {
        cn1(this.getElement(), 'button-delete-limit-date')?.removeEventListener('click', this._onDeleteBind)
    }

    private setupLimitDateDialog(): void {
        // 回答期限なしボタンが押されたときの処理
        this._onDeleteBind = this.onDeleteDate.bind(this)
        cn1(this.getElement(), 'button-delete-limit-date')?.addEventListener('click', this._onDeleteBind)

    }
}