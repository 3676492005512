import { BaseModal } from "../common/BaseModal";

/** スポット選択ダイアログのID */
const SPOT_SELECTOR_MODAL_ID = 'selectSpotModal'

export interface SpotSelectOptions {
    spotId?: string
    confirmed?: (spotId: string) => void
}

function createSpotSelectorModalElement(): HTMLElement {
    const p = document.getElementById(SPOT_SELECTOR_MODAL_ID)
    if (!p) {
        throw 'Cannot find spot selector modal.'
    }
    return p
}

/**
 * スポット選択ダイアログ
 */
export class SpotSelector extends BaseModal {
    private _options: SpotSelectOptions

    private _selectedSpotId: string

    constructor(options: SpotSelectOptions) {
        super(createSpotSelectorModalElement())

        this._options = options

        // ダイアログセットアップ
        this.setupSpotSelector()
    }

    private setupSpotSelector(): void {
        // 「選択」ボタンが押されたときの処理を登録
        const buttons = this.getElement().getElementsByClassName('button-select-spot')
        for (const b of buttons) {
            b.addEventListener('click', () => {
                const spodId = b.getAttribute('data-spot-id')
                if (spodId) {
                    this._selectedSpotId = spodId
                    this.onModalConfirm()
                }
            })
        }
    }

    protected onConfirmed(): void {
        if (this._options.confirmed) {
            this._options.confirmed(this._selectedSpotId)
        }
    }
}