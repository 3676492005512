/**
 * 管理者機能側JavaScript
 */

import * as utils from './utils'
import * as forms from './forms'
import { ConditionEditor } from './conditionEditor'
import { FormEditor, LimitDateSelector } from './formEditor'
import { ImageSelector } from './imageSelector'
import { MessageEditor, ReserveDateSelector, SegmentSelector } from './messageEditor'
import { PostbackActionEditor } from './postbackActionEditor'
import { RichMenuEditor } from './richMenuEditor'
import { QRActionEditor } from './qrActionEditor'
import { InputProfile } from './inputProfile/InputProfile'
import { Login } from './login'
import { setupDashboardInformation } from './dashboard'
import { setupCategoryMovable } from './categoryExplorer'

import * as bootstrap from 'bootstrap'

import '../css/albase.user.scss'

export const windowOpen = utils.windowOpen
export const addOnLoad = utils.addOnLoad
export const ge = utils.ge
export const clipboardCopy = utils.clipboardCopy
export const loadingButton = utils.loadingButton
export const showProgressRing = utils.showProgressRing
export const hideProgressRing = utils.hideProgressRing
export const convertDataUri2Blob = utils.convertDataUri2Blob
export const saveAs = utils.saveAs

export {
    ConditionEditor,
    FormEditor,
    LimitDateSelector,
    ImageSelector,
    MessageEditor,
    ReserveDateSelector,
    SegmentSelector,
    PostbackActionEditor,
    RichMenuEditor,
    QRActionEditor,
    InputProfile,
    Login,

    setupDashboardInformation,

    setupCategoryMovable,

    bootstrap,
}


function enablePopover() {
    const p = document.querySelectorAll('[data-bs-toggle="popover"]')
    if (p) {
        [...p].map(el => new bootstrap.Popover(el))
    }
}

function setupLoadingButton() {
    document.querySelectorAll('.loading').forEach(p => {
        if (p instanceof HTMLButtonElement) {
            p.addEventListener('click', () => loadingButton(p))
        }
    })
}

function setup() {
    enablePopover()
    setupLoadingButton()
    utils.disableFormEnter()
    forms.setupDateTimeFromToForm()
    forms.setupDatePickerForm()
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => setup())
} else {
    setup()
}
