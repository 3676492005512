import { Modal } from "bootstrap"
import { cn1 } from "../utils"

const DAHSBOARD_INFORMATION_ITEM = 'dashboard-information-item'
const INFORMATION_MODAL_ID = 'information-modal'

function showInformationModal(t: HTMLElement) {
    const modalElem = document.getElementById(INFORMATION_MODAL_ID)
    if (!modalElem) {
        return
    }

    const date = cn1(t, 'info-date')?.innerText ?? ''
    const title = cn1(t, 'info-title')?.innerText ?? ''
    const body = cn1(t, 'info-body')?.innerHTML ?? ''

    const titleElem = cn1(modalElem, 'modal-title')
    if (titleElem) {
        titleElem.innerText = title
    }
    const dateElem = cn1(modalElem, 'modal-info-date')
    if (dateElem) {
        dateElem.innerText = date
    }
    const bodyElem = cn1(modalElem, 'modal-info-body')
    if (bodyElem) {
        bodyElem.innerHTML = body
    }

    const modal = Modal.getOrCreateInstance(modalElem)
    modal.show()
}

export function setupDashboardInformation(): void {
    const items = document.getElementsByClassName(DAHSBOARD_INFORMATION_ITEM)
    for (const t of items) {
        if (t instanceof HTMLElement) {
            t.addEventListener('click', () => {
                showInformationModal(t)
            })
        }
    }
}