import { Modal } from 'bootstrap'
import { hideProgressRing, showProgressRing } from '../utils'

const SEGMENT_SELECTOR_MODAL_ID = 'select-segment-group-modal'

type GroupCountData = {
    count: Number,
    groupName: string,
    segmentGroupId: string
}

export interface SegmentSelectOptions {
    groupId: string
    getCount: string
    confirmed?: (data: GroupCountData) => void
}

/**
 * 配信グループセレクタ
 */
export class SegmentSelector {
    private _element: HTMLElement
    private _modal: Modal

    private _options: SegmentSelectOptions

    private _confirmed: boolean = false
    private _selectedGroupId: string

    private _onConfirmBind
    private _onModalClosedBind

    constructor(options: SegmentSelectOptions) {
        this._element = this.createDialog()
        this._options = options

        // 画面に反映
        this.apply(options.groupId)

        // bootstrap Modalを生成
        this._modal = new Modal(this._element)
    }

    show(): void {
        this._modal.show()
    }

    private apply(groupId: string): void {
        const compareGroupId = groupId ? groupId : '____'
        this._element.querySelectorAll('div.segment-selector').forEach(d => {
            const divGroupId = d.getAttribute('data-group-id')
            if (divGroupId == compareGroupId) {
                d.classList.add('group-selected')
            } else {
                d.classList.remove('group-selected')
            }
        })
    }

    private queryButtons(element?: HTMLElement): NodeListOf<Element> {
        let target = element
        if (!target) {
            target = this._element
        }
        return target.querySelectorAll('button.btn-select')
    }

    private onModalConfirm(evt: Event): void {
        const btn = evt.target as HTMLElement
        if (!btn) {
            return
        }
        this._selectedGroupId = btn.getAttribute('data-group-id') ?? ''
        // 設定OKフラグ設定
        this._confirmed = true
        // モーダルを閉じる
        this._modal.hide()
    }

    private async onModalClosed(): Promise<void> {
        if (this._confirmed) {
            // 確定したのでOK時のハンドラを実行
            if (this._options.confirmed) {
                const obj = await this.buildSegmentInfo()
                this._options.confirmed(obj)
            }
        }
        // ダイアログ破棄
        this.dispose()
    }

    private async buildSegmentInfo(): Promise<GroupCountData> {
        showProgressRing()
        try {
            const r = await fetch(this._options.getCount + '?groupId=' + this._selectedGroupId)
            return await r.json()
        } finally {
            hideProgressRing()
        }
    }

    private createDialog(): HTMLElement {
        const p = document.getElementById(SEGMENT_SELECTOR_MODAL_ID)
        if (!p) {
            throw 'Cannot find reserve date selector modal'
        }

        // 設定ボタン選択時の処理
        this._onConfirmBind  = this.onModalConfirm.bind(this)
        this.queryButtons(p).forEach(t => {
            t.addEventListener('click', this._onConfirmBind)
        })

        // モーダルが閉じられた時の処理
        this._onModalClosedBind = this.onModalClosed.bind(this)
        p.addEventListener('hidden.bs.modal', this._onModalClosedBind)

        return p
    }

    private dispose() {
        this.queryButtons().forEach(t => {
            t.removeEventListener('click', this._onConfirmBind)
        })
        this._element.removeEventListener('hidden.bs.modal', this._onModalClosedBind)
        this._modal.dispose()
    }
}