
/**
 * リッチメニューアクション種別
 */
export const RICHMENU_ACTION_TYPES = {
    NONE: 'NONE',
    LINK: 'LINK',
    BUILTIN_LINK: 'BUILTIN_LINK',
    FLEX_LINK: 'FLEX_LINK',
    PROFILE: 'PROFILE',
    QR_READER: 'QR_READER',
    POSTBACK: 'POSTBACK',
    KEYWORD: 'KEYWORD',
    FORM: 'FORM',
    SWITCH_TAB: 'SWITCH_TAB',
} as const

/**
 * リッチメニューアクション種別
 */
export type RichMenuActionType = typeof RICHMENU_ACTION_TYPES[keyof typeof RICHMENU_ACTION_TYPES]

/**
 * 文字列からアクション種別を取得
 */
export function richMenuActionTypeOf(s: string): RichMenuActionType {
    const x = Object.values(RICHMENU_ACTION_TYPES).filter(t => t == s)
    if (x) {
        return x[0]
    } else {
        return RICHMENU_ACTION_TYPES.NONE
    }
}