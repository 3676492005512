import { ActionDefinition } from "../actions/actionObjects"
import { BaseComponent } from "../common/BaseComponent"
import { cloneNode, cn1, hide, show } from "../utils"
import { ActionPanel } from "./ActionPanel"

/** アクションリストを格納しているDOMのクラス名 */
const ACTION_LIST_PANEL_CLASS_NAME = 'action-list-panel'
/** アクション未作成時メッセージDOMのクラス名 */
const ACTION_EMPTY_PANEL_CLASS_NAME = 'action-empty-panel'

/**
 * アクションエディターベース
 */
export abstract class BaseActionEditor extends BaseComponent{
    /** アクションエディタ格納用のコンテナDOM */
    protected _container: HTMLElement
    /** アクションリストパネル */
    protected _actionListPanel: HTMLElement
    /** アクション未作成時のメッセージ */
    protected _actionEmptyPanel: HTMLElement

    /** 作成しているアクションパネルのリスト */
    private _actions: ActionPanel[] = []

    /**
     * コンストラクタ
     * 
     * @param container エディタを格納するコンテナDOM
     * @param editorTemplateId エディタ本体テンプレートDOMのID
     */
    constructor(container: HTMLElement, editorTemplateId: string) {
        super()
        this._container = container

        this.setupPanels(editorTemplateId)
    }

    /**
     * アクションエディタの初期化
     * 
     * @param actionsJson アクション詳細JSONデータ
     */
    init(actionsJson: string): void {
        // アクションが設定されていれば画面に反映
        if (actionsJson) {
            this.applyActionsJson(JSON.parse(actionsJson))
        }

        // アクションリストパネル表示切替
        this.updateActionListVisible()
    }

    /**
     * アクション定義JSONデータを生成
     */
    buildActionsJson(): string {
        const actions: ActionDefinition[] = []
        for (let n = 0; n < this._actions.length; n++) {
            console.dir('ho')
            // 各アクションの定義オブジェクト生成
            const b = this._actions[n].buildActionJson()
            if (!b) {
                // 生成してない場合はどこかにエラーがある
                return ''
            }
            // 表示順を設定
            b.order = n
            // 結果用配列に追加
            actions.push(b)
        }
        return JSON.stringify(actions)
    }

    /**
     * アクション設定パネルを追加
     */
    private addAction(action: ActionPanel): void {
        // アクション削除処理
        action.setOnRemovedHandler(() => this.onActionRemoved(action))

        // 内部配列に追加
        this._actions.push(action)
        // アクションDOMもエディタに追加
        this._actionListPanel.appendChild(action.getElement())
    }

    /**
     * エディタパネルのセットアップ
     * 
     * @param editorTemplateId エディタ本体テンプレートDOMのID
     */
    private setupPanels(editorTemplateId: string): void {
        // エディタDOMをクローン
        const editor = cloneNode(editorTemplateId)
        if (!editor) {
            throw `Cannot find action editor container: ${editorTemplateId}`
        }

        // アクションリストパネルを取得
        const actionListPanel = cn1(editor, ACTION_LIST_PANEL_CLASS_NAME)
        if (!actionListPanel) {
            return
        }
        // アクション未作成パネルを取得
        const actionEmptyPanel = cn1(editor, ACTION_EMPTY_PANEL_CLASS_NAME)
        if (!actionEmptyPanel) {
            return
        }

        this._actionListPanel = actionListPanel
        this._actionEmptyPanel = actionEmptyPanel

        // アクション追加ボタンクリック時の処理
        const addBtn = cn1(editor, 'add-action-button')
        if (addBtn) {
            addBtn.addEventListener('click', () => {
                // 新規アクション追加
                this.addAction(ActionPanel.createNewPanel())
                // アクションリストパネル表示切替
                this.updateActionListVisible()
            })
        }

        // エディタ用コンテナに追加
        this._container.appendChild(editor)

        show(editor)
    }

    /**
     * アクション定義データを画面に反映
     * 
     * @param actions アクション定義リスト
     */
    private applyActionsJson(actions: ActionDefinition[]): void {
        // アクションデータを作成していく
        actions.forEach(v => {
            // パネル作成
            const act = new ActionPanel()
            // アクション定義をパネルに反映
            act.applyAction(v)
            // エディタに追加
            this.addAction(act)
        })
    }

    /**
     * アクションが削除されたときの処理
     * 
     * @param action 削除されたアクション
     */
    private onActionRemoved(action: ActionPanel): void {
        // アクションリストから削除されたアクションも削除
        this._actions = this._actions.filter(t => t != action)
        // DOM要素からも削除
        this._actionListPanel.removeChild(action.getElement())
    }

    /**
     * アクションリストパネル表示切替
     */
    protected updateActionListVisible(): void {
        if (this._actions.length > 0) {
            // アクションが未作成メッセージパネルは非表示
            hide(this._actionEmptyPanel)
            // アクションリストを表示
            show(this._actionListPanel)
        } else {
            // アクションリストは非表示
            hide(this._actionListPanel)
            // アクションが未作成メッセージパネルを表示
            show(this._actionEmptyPanel)
        }
    }
}