import { ACTION_TYPES } from "./actionObjects"
import { BaseTagAction } from "./BaseTagAction"

/**
 * タグ削除アクション
 */
export class RemoveTagAction extends BaseTagAction {
    constructor(parent: HTMLElement) {
        super(parent, 'action-remove-tag', ACTION_TYPES.REMOVE_TAG)
    }
}