import { TagSelector } from "../tagSelector"
import { cloneNode, cn1, show } from "../utils"
import { ActionDefinition, ActionType, TagItem } from "./actionObjects"
import { BaseAction } from "./BaseAction"

/** タグリストパネルのクラス名 */
const PANEL_TAGS_CLASSNAME = 'action-tags'
/** タグDOMテンプレートのID */
const BADGE_TAG_TEMPLATE_ID = 'tag-badge-template'

/**
 * タグ関連アクション
 */
export abstract class BaseTagAction extends BaseAction {
    protected _tagsPanel: HTMLElement

    private _tags: TagItem[] = []

    constructor(parent: HTMLElement, panelClassName: string, type: ActionType) {
        super(parent, panelClassName, type)

        // タグリスト
        const p = cn1(this._container, PANEL_TAGS_CLASSNAME)
        if (!p) {
            return
        }

        this._tagsPanel = p
        this.applyTags([])

        // タグ選択ボタンが追加されたときの処理
        const btn = cn1(this._container, 'action-select-tag')
        btn?.addEventListener('click', () => this.showTagSelector())
    }

    apply(action: ActionDefinition): void {
        this.applyTags(action.tags ?? [])
    }

    buildActionJson(): ActionDefinition | null {
        if (this._tags.length == 0) {
            // タグが選択されてない
            this._tagsPanel.parentElement?.classList.add('is-invalid')
            return null
        }

        // エラー表示解除
        this._tagsPanel.parentElement?.classList.remove('is-invalid')
        // タグリスト作成
        const tagIds = this._tags.map(t => { return {id: t.id}})

        return {
            type: this._type,
            tags: tagIds,
        }
    }

    /**
     * タグ情報を画面に反映
     */
    private applyTags(tags: TagItem[]): void {
        // 一旦タグリストの中身をクリア
        this._tagsPanel.innerText = ''

        if (tags.length > 0) {
            const fragment = document.createDocumentFragment()
            tags.forEach(o => fragment.appendChild(this.createTagBadge(o)))
            this._tagsPanel.appendChild(fragment)
        } else {
            // タグが無いので、「未選択」を表示
            const d = document.createElement('div')
            d.classList.add('text-danger')
            d.innerText = 'タグが選択されていません。'
            this._tagsPanel.appendChild(d)
        }

        // タグ空欄でアクション定義生成したらエラー枠線が表示されるので、タグ編集時にクリアしておく
        this._tagsPanel.parentElement?.classList.remove('is-invalid')
        // 選択したタグを保持
        this._tags = tags
    }

    /**
     * タグを画面に表示するためのDOMを生成
     * 
     * @param tag タグ情報
     */
    private createTagBadge(tag: TagItem): HTMLElement {
        // タグ表示用DOMを作成
        const d = cloneNode(BADGE_TAG_TEMPLATE_ID)
        if (!d) {
            throw 'Cannot create TAG badge element.'
        }

        // タグ内容を反映
        const span = d.children[0] as HTMLElement
        if (tag.name) {
            span.innerText = tag.name
        }
        if (tag.style) {
            span.style.cssText = tag.style
        }
        span.setAttribute('data-tag-id', tag.id)

        // 表示
        show(d)

        return d
    }

    /**
     * タグ選択ダイアログを表示
     */
    private showTagSelector(): void {
        const s = new TagSelector({
            tags: this._tags.map(t => t.id),
            confirmed: tags => this.applyTags(tags),
            limit: 10,
        })
        s.show()
    }
}