import { TagItem } from "../actions/actionObjects";
import { BaseModal } from "../common/BaseModal";
import { cn1, ge } from "../utils";

/** タグ選択ダイアログのID */
const TAG_SELECTOR_MODAL_ID = 'assignTagsModal'

export interface TagSelectOptions {
    /** 選択済みタグリスト */
    tags: string[]
    /** 選択確定時の処理 */
    confirmed?: (selectedTags: TagItem[]) => void
    /** 選択個数上限 */
    limit?: number
}

function createTagSelectorModalElement(): HTMLElement {
    const p = document.getElementById(TAG_SELECTOR_MODAL_ID)
    if (!p) {
        throw 'Cannot find tag selector modal'
    }
    return p
}

/**
 * タグ選択ダイアログ
 */
export class TagSelector extends BaseModal {
    private _options: TagSelectOptions

    constructor(options: TagSelectOptions) {
        super(createTagSelectorModalElement())

        this._options = options

        this.applySelectedTags(options.tags)
    }

    /**
     * 画面に選択済みタグを反映
     * 
     * @param tags 設定するタグIDのリスト
     */
    private applySelectedTags(tags: string[]): void {
        // 選択を一旦クリア
        this.clearSelectedTags()

        tags.forEach(t => {
            const e = document.getElementById(t)
            if (e instanceof HTMLInputElement) {
                e.checked = true
            }
        })

        this.hideMessage()
    }

    /**
     * 選択済みタグをクリア
     */
    private clearSelectedTags(): void {
        const checks = this.getElement().getElementsByClassName('form-check-input')
        for (const c of checks) {
            if (c instanceof HTMLInputElement) {
                c.checked = false
            }
        }
    }

    /**
     * 選択確定したときの処理
     */
    protected onConfirmed(): void {
        if (this._options.confirmed) {
            // 選択したタグを集める
            const tags = this.collectSelectedTags()
            // 確定時ハンドラを実行
            this._options.confirmed(tags)
        }
    }

    /**
     * 入力値検証
     */
    protected validate(): boolean {
        // まずは選択されているタグを取得
        const checks = this.getElement().querySelectorAll('.form-check-input:checked')
        // 選択個数チェック
        if (this._options.limit && checks.length > this._options.limit) {
            // 選択個数オーバー
            this.showMessage(`タグは${this._options.limit}個まで設定可能です`)
            return false
        }

        // 検証OK
        this.hideMessage()
        return true
    }

    private showMessage(msg: string): void {
        const span = cn1(this.getElement(), 'tags-warning-message')
        if (span) {
            span.innerText = msg
            span.parentElement?.classList.remove('d-none')
        }
    }

    private hideMessage(): void {
        const span = cn1(this.getElement(), 'tags-warning-message')
        if (span) {
            span.parentElement?.classList.add('d-none')
        }
    }

    private collectSelectedTags(): TagItem[] {
        const selectedTags: TagItem[] = []
        const checks = this.getElement().getElementsByClassName('form-check-input')
        for (const c of checks) {
            if ((c instanceof HTMLInputElement) && c.checked) {
                const span = ge(`span-${c.id}`)
                const tagInfo: TagItem = {
                    id: c.value,
                    name: span?.innerText ?? '',
                    style: span?.getAttribute('style') ?? ''
                }
                selectedTags.push(tagInfo)
            }
        }
        return selectedTags
    }
}