/**
 * Blobデータをローカルにダウンロード
 * 
 * @param blob 保存するBlobデータ
 * @param filename ファイル名
 */
export function saveAs(blob: Blob, filename: string): void {
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(blob)
    a.download = filename
    a.click()
}